import React from 'react';
import { Link } from 'gatsby';
import { Seo } from '../../../modules/common/components/seo';
import { DashboardLayout } from '../../../modules/layout/components/dashboard-layout';
import { StaticImage } from 'gatsby-plugin-image';
import { SectionHeader } from '../../../modules/common/components/section-header';

import '../../generic-page.scss';
import '../index.scss';
import './afk-guide.scss';
import { Row, Col } from 'react-bootstrap';
import { AFKCharacter } from '../../../modules/afk/common/components/afk-character';
import { AFKItem } from '../../../modules/afk/common/components/afk-item';
import { YoutubeEmbed } from '../../../modules/common/components/youtube-embed';

const AFKGuidesEq: React.FC = () => {
  return (
    <DashboardLayout
      className={'generic-page afk-journey afk-guide'}
      game="afk"
    >
      <ul className="breadcrumb">
        <li>
          <Link to="/afk-journey/">AFK Journey</Link>
        </li>
        <li className="divider">/</li>
        <li>
          <Link to="/afk-journey/guides">Guides</Link>
        </li>
        <li className="divider">/</li>
        <li>Equipment & upgrade priority</li>
      </ul>
      <div className="page-header">
        <div className="page-icon">
          <StaticImage
            src="../../../images/afk/categories/category_eq.jpg"
            alt="Guides"
          />
        </div>
        <div className="page-details">
          <h1>AFK Journey Equipment & upgrade priority</h1>
          <h2>Equipment & upgrade priority in AFK Journey.</h2>
          <p>
            Last updated: <strong>17/03/2024</strong>
          </p>
        </div>
      </div>
      <div className="fuse-ad-placeholder" data-fuse="22844297235"></div>
      <div className="page-content">
        <SectionHeader title="Equipment" />
        <StaticImage
          src="../../../images/afk/generic/beginner_5.webp"
          alt="Guides"
        />
        <p>
          <strong>
            Characters in AFK Journey don't need to equip gear anymore
          </strong>
          , as the system has been replaced by Class-based Equipment system -
          you simply gather gear that the Class can wear and every character
          within the Class will automatically wear it in the battle. This makes
          gear management not only much easier to handle, but also it allows you
          to try different characters and easily swap them before the battle
          when you need their specific skillset.
        </p>
        <Row xs={1} xxl={2}>
          <Col>
            <h5>Equipment pieces</h5>
            <StaticImage
              src="../../../images/afk/generic/beginner_7.webp"
              alt="Guides"
            />
            <p>
              Each class can equip 6 different pieces of equipment:{' '}
              <strong>Weapon, Gloves, Accessory, Helm, Armor, and Boots</strong>
              . Each piece also comes with different stats - some are more
              offensive, some more defensive - and this means that for different
              classes,{' '}
              <strong>
                you want to prioritize upgrading different pieces first
              </strong>
              .
            </p>
          </Col>
          <Col>
            <h5>Equipment upgrading</h5>
            <StaticImage
              src="../../../images/afk/generic/beginner_6.webp"
              alt="Guides"
            />
            <p>
              While you can drop higher item level equipment from the AFK stages
              or obtain them as rewards from quests, you can also{' '}
              <strong>Forge and upgrade</strong> the pieces manually using{' '}
              <strong>Casting Shards</strong> you obtain from Recycling gear you
              no longer need.
            </p>
            <p>
              The level of the equipment you can forge is tied to your Resonance
              level.
            </p>
          </Col>
        </Row>
        <p>For more information check this video:</p>
        <SectionHeader title="Video guide" />
        <Row xs={1} xl={2} className="video-row">
          <Col>
            <YoutubeEmbed embedId="pChgNFgmzMU" />
          </Col>
        </Row>
        <SectionHeader title="Upgrade priority" />
        <h5>Class priority</h5>
        <p>
          Which Class gear you should focus on upgrading first? In nearly all
          cases you will prioritize upgrading <AFKItem name="Marksman" />{' '}
          because the damage dealers belonging to this class are considered the
          best for both Story progression and Dream Realm.
        </p>
        <p>
          Characters like{' '}
          <AFKCharacter mode="inline" slug="cecia" enablePopover />{' '}
          <AFKCharacter mode="inline" slug="odie" enablePopover /> or{' '}
          <AFKCharacter mode="inline" slug="marilee" enablePopover /> will be
          raised on everyone's account.
        </p>
        <p>
          After <AFKItem name="Marksman" /> you should focus on both{' '}
          <AFKItem name="Tank" /> and <AFKItem name="Support" />. Better gear
          will help your Tank to survive longer while the Support gear will
          allow them to use their Ultimate faster. Lastly, you should check
          which of secondary damage dealers you're using when picking between{' '}
          <AFKItem name="Mage" /> <AFKItem name="Rogue" /> and{' '}
          <AFKItem name="Warrior" /> as the next priority.
        </p>
        <h5>Piece priority</h5>
        <p>
          Below you will find the priority of upgrading your gear to higher
          levels for each class.
        </p>
        <h5>
          <AFKItem name="Marksman" />
        </h5>
        <StaticImage
          src="../../../images/afk/generic/gear_mark.webp"
          alt="Guides"
        />
        <h5>
          <AFKItem name="Rogue" />
        </h5>
        <StaticImage
          src="../../../images/afk/generic/gear_rogue.webp"
          alt="Guides"
        />
        <h5>
          <AFKItem name="Mage" />
        </h5>
        <StaticImage
          src="../../../images/afk/generic/gear_mage.webp"
          alt="Guides"
        />
        <h5>
          <AFKItem name="Warrior" />
        </h5>
        <StaticImage
          src="../../../images/afk/generic/gear_warrior.webp"
          alt="Guides"
        />
        <h5>
          <AFKItem name="Tank" />
        </h5>
        <StaticImage
          src="../../../images/afk/generic/gear_tank.webp"
          alt="Guides"
        />
        <h5>
          <AFKItem name="Support" />
        </h5>
        <StaticImage
          src="../../../images/afk/generic/gear_support.webp"
          alt="Guides"
        />
      </div>
      <div className="fuse-ad-placeholder" data-fuse="22844297238"></div>
    </DashboardLayout>
  );
};

export default AFKGuidesEq;

export const Head: React.FC = () => (
  <Seo
    title="Equipment & upgrade priority | AFK Journey | Prydwen Institute"
    description="Equipment & upgrade priority in AFK Journey."
    game="afk"
  />
);
